<template>
  <div class="w-full text-sm">
    <card-form>
      <template #title>Salesforce</template>
      <template #form>
        <div class="flex flex-wrap items-center justify-end w-full text-gray-700 xl:w-auto">
          <form-row>
            <template #left>
              <sun-label-group text="Transaction ID">
                <sun-input
                  v-model="deal.idOpportunity"
                  type="text"
                  name="idOpportunity"
                  placeholder="Transaction ID"
                  disabled
                />
              </sun-label-group>
            </template>
          </form-row>
          <form-row>
            <template #left>
              <sun-label-group text="Amount">
                <sun-input :value="amounFormated" type="text" name="amount" placeholder="Amount" disabled />
              </sun-label-group>
            </template>
            <template #right>
              <sun-label-group text="Seller">
                <sun-select
                  :options="sellerList"
                  :value="seller"
                  name="seller-select"
                  track-by="id"
                  disabled
                  label="name"
                  is-array-object
                />
              </sun-label-group>
            </template>
          </form-row>
          <form-row>
            <template #left>
              <sun-label-group text="Start Date">
                <sun-date-picker name="startDate" class="w-full" :value="startDate" cancel-error disabled />
              </sun-label-group>
            </template>
            <template #right>
              <sun-label-group text="End Date">
                <sun-date-picker name="endDate" class="w-full" :value="endDate" cancel-error disabled />
              </sun-label-group>
            </template>
          </form-row>
          <form-row>
            <template #left>
              <sun-label-group text="Account">
                <sun-input v-model="deal.account" type="text" name="account" placeholder="Account" disabled />
              </sun-label-group>
            </template>
            <template #right>
              <sun-label-group text="Brand">
                <sun-input v-model="deal.brand" type="text" name="brand" placeholder="Brand" disabled />
              </sun-label-group>
            </template>
          </form-row>
          <form-row>
            <template #left>
              <sun-label-group text="Type">
                <sun-input v-model="deal.type" type="text" name="type" placeholder="Type" disabled />
              </sun-label-group>
            </template>
          </form-row>
        </div>
      </template>
    </card-form>
  </div>
</template>

<script>
import metaInfo from '@/mixins/common/metaInfo';
import CardForm from '@/components/atoms/CardForm';
import FormRow from '@/components/atoms/FormRow/FormRow.vue';

import { getSalesforceUserById } from '@/services/modules/Core/salesforce';

import Deal from '@/entities/dashboardSSP/Deal';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';

export default {
  name: 'Detail',
  components: {
    CardForm,
    FormRow,
  },
  mixins: [metaInfo],
  props: {
    deal: { type: Deal, default: null },
  },
  data: () => ({
    seller: null,
    sellerList: [],
  }),
  computed: {
    startDate() {
      return {
        startDate: this.deal.start,
        endDate: this.deal.start,
      };
    },
    endDate() {
      return {
        startDate: this.deal.end,
        endDate: this.deal.end,
      };
    },
    amounFormated() {
      return this.$options.filters.currency(this.deal.amount.value, this.deal.amount.currency);
    },
  },
  mounted() {
    this.getSellerById(this.deal.seller.id);
  },
  methods: {
    metaTitleReplacement() {
      return { deal: this.deal.name };
    },
    async getSellerById(id) {
      try {
        const { data } = await getSalesforceUserById(id);
        this.sellerList = [data];
        this.seller = data;
      } catch (error) {
        if (error.code !== HTTPStatusCode.Cancel) {
          this.seller = { name: 'N/A' };
          this.sellerList = [];
        }
      }
    },
  },
};
</script>
